import React from "react"
import {
  notificationBar,
  notificationText,
  close,
} from "./notification-bar.module.css"
import { FaTimes } from "react-icons/fa"
import BodyClassName from "react-body-classname"

const NotificationBar = ({ notification }) => {
  //if (!notification) return <></>
  return (
    <BodyClassName className="has-notification">
      <div className={`${notificationBar} clearfix`}>
        <div className={notificationText}>
          <p>
            Kan jij meer bezuinigen? Doe de MKB Servicedesk Bezuinigingsscan!
            Klik hier om verder te gaan.
          </p>
        </div>
        <div className={close}>
          <FaTimes />
        </div>
      </div>
    </BodyClassName>
  )
}

export default NotificationBar
