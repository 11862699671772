module.exports = {
    isOwnDomain: (link) => {
        try {
            const urlObject = new URL(link);
            const arr = urlObject.hostname.split('.');
            return (`${arr[arr.length - 2]}.${arr[arr.length - 1]}` === 'mkbservicedesk.nl');
        } catch {
            return false;
        }
    },
    readTimeFromRichText: (rawBody) => {
        if (!rawBody) return;
        return wordsToMinutes(wordsInText(richtTextToPlainText(rawBody)));
    },
    mostRecentDate: (dateA, dateB) => {
        if (!dateB) return dateA;
        if (!dateA) return dateB;
        return (new Date(dateA) > new Date(dateB)) ? dateA : dateB;
    },
    uniqueArrayByParam: (arr, property) => {
        let newArr = [];
        arr.forEach(item => {
            if (!newArr.some(e => e[property] === item[property])) {
                newArr.push(item);
            }
        })
        return newArr;
    }

}

function wordsToMinutes(wordCount) {
    return Math.ceil(wordCount / 250);
}

function wordsInText(text) {
    return text.split(' ').length;
}

function richtTextToPlainText(rawBody) {
    try {
        const parsedBody = JSON.parse(rawBody);
        if (!parsedBody.content) return;
        const textOnly = parsedBody.content.map(node => {
            return parseRichTextNode(node);
        })
        return textOnly.join(' ');
    } catch {
        return 0;
    }

    function parseRichTextNode(node) {
        if (node.content && Array.isArray(node.content) && node.content.length) {
            return node.content.map(childNode => parseRichTextNode(childNode));
        }
        if (node.nodeType && node.nodeType === 'text') {
            return node.value;
        }
    }
}


