import React from "react"
import {
  gradientBottom,
  gradientTop,
  container,
  heroSubtitle,
  pubDetails,
  updateDate,
  readTime as readTimeClass,
} from "./hero.module.css"
import { readTimeFromRichText, mostRecentDate } from "../../modules/helpers"
import { FaSyncAlt, FaClock } from "react-icons/fa"

const HeroContent = ({ data, gradient }) => {
  let readTime
  if (!data) return <></>
  if (data.body?.raw) readTime = readTimeFromRichText(data.body.raw)
  const mostRecent = mostRecentDate(data.publishDate, data.updateDate)
  return (
    <>
      {gradient !== "off" && (
        <>
          <div className={gradientBottom}></div>
          <div className={gradientTop}></div>
        </>
      )}
      <div className={container}>
        {(data?.title || data?.name) && <h1>{data?.title || data?.name}</h1>}

        {(data?.subtitle || (data?.desk && data?.desk?.name)) && (
          <p className={heroSubtitle}>{data?.subtitle || data?.desk?.name}</p>
        )}

        <div className={pubDetails}>
          {mostRecent && (
            <span className={updateDate}>
              <FaSyncAlt /> {mostRecent}
            </span>
          )}
          {readTime && (
            <span className={readTimeClass}>
              <FaClock /> {readTime} minuten
            </span>
          )}
        </div>
      </div>
    </>
  )
}

export default HeroContent
