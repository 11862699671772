import React from "react"
import { header as headerClass } from './header.module.css'

/* Components */
import StickyNav from '../sticky-nav/sticky-nav'
import NotificationBar from '../notification-bar/notification-bar'
import Hero from '../hero/hero'

const Header = ({ data, backgroundImage, notification, desks, gradient }) => {
  return (
    <header className={headerClass}>
      <StickyNav desks={desks} />
      {notification &&
        <NotificationBar notification={notification} />
      }
      <Hero data={data} backgroundImage={backgroundImage} gradient={gradient} />
    </header>
  )
}

export default Header
