import React from "react"
import { hero } from "./hero.module.css"
import HeroContent from "./hero-content"
import BackgroundImage from "gatsby-background-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"

const Hero = ({ data, backgroundImage, gradient }) => {
  const image = getImage(backgroundImage);
  const bgImage = convertToBgImage(image);

  if (backgroundImage)
    return (
      <BackgroundImage className={hero} {...bgImage}>
        <HeroContent data={data} gradient={gradient} />
      </BackgroundImage>
    )
  else
    return (
      <div className={hero}>
        <HeroContent data={data} gradient={gradient} />
      </div>
    )
}

export default Hero
