// extracted by mini-css-extract-plugin
export var container = "hero-module--container--ada1a";
export var desk = "hero-module--desk--189f4";
export var gradientBottom = "hero-module--gradient-bottom--e5bcb";
export var gradientTop = "hero-module--gradient-top--a2c0f";
export var hero = "hero-module--hero--d526b";
export var heroSubtitle = "hero-module--hero-subtitle--74c88";
export var pubDate = "hero-module--pub-date--0c2c1";
export var pubDetails = "hero-module--pub-details--93284";
export var readTime = "hero-module--read-time--bdff5";
export var updateDate = "hero-module--update-date--0c836";