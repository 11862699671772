import React from "react"
import { graphql, Link } from "gatsby"
import { Helmet } from "react-helmet"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import Layout from "../components/layout"
import linkBuilder from "../modules/linkBuilder"
import Header from "../components/header/header"
import {
  intro,
  deskContent,
  data as dataClass,
  content,
  deskGrid,
} from "./desken.module.css"

const DesksPage = ({ data }) => {
  const image = getImage(data.allContentfulDesk.nodes[0].image);
  const bgImage = convertToBgImage(image);
  return (
    <Layout>
      <Helmet>
        <link rel="canonical" href="https://www.mkbservicedesk.nl/desken/" />
      </Helmet>
      <Header
        desks={data.allContentfulDesk.nodes}
        data={{
          name: "Alle desken",
          subtitle:
            "Op MKB Servicedesk vind je informatie over alle thema’s rondom ondernemen.",
        }}
      />
      <main className="main">
        <div className="container">
          <div className={`${deskContent} clearfix`}>
            <p className={intro}>
              Zoek je informatie over een specifiek onderwerp? Kijk dan eens in
              een van onze desken.
            </p>

            <section className="clearfix"></section>
            <div id="desk-links" className={deskGrid}>
              {data.allContentfulDesk.nodes.map((desk, index) => {
                return (
                  <article key={`desk-${desk.id}`} className="desk-grid-card">
                    <Link to={linkBuilder.desk(desk)}>
                      <BackgroundImage {...bgImage} >
                        <div className={dataClass}>
                          <div className={content}>
                            <h1 className="title">{desk.name}</h1>
                          </div>
                        </div>
                      </BackgroundImage>
                    </Link>
                  </article>
                )
              })}
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default DesksPage

export const pageQuery = graphql`
  query {
    allContentfulDesk(sort: { fields: priority, order: DESC }) {
      nodes {
        name
        slug
        legacyId
        sortNavigation
        image {
          gatsbyImageData(layout: CONSTRAINED, width: 400)
        }
      }
    }
  }
`
